import {Injectable} from "@angular/core";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment';

import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class AssociadoSituacaoTipoService {

  private _Url = environment.UrlPrincipal + '/api/associadosituacaotipo';

  constructor(private http: HttpClient) {}

  list() {
    return this.http.get(this._Url)
            .catch(this.handleError);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
