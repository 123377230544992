import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from 'src/app/core/authentication/auth.guard';
import { PlanoListarComponent } from '../tabela-apoio/plano/plano-listar/plano-listar.component';
import { PlanoEditarComponent } from '../tabela-apoio/plano/plano-editar/plano-editar.component';
import { ClasseListarComponent } from '../tabela-apoio/classe/classe-listar/classe-listar.component';
import { ClasseEditarComponent } from '../tabela-apoio/classe/classe-editar/classe-editar.component';

const appRoutes: Routes = [
  { path: 'plano-listar', component: PlanoListarComponent, canActivate: [AuthGuard] },
  { path: 'plano-editar', component: PlanoEditarComponent, canActivate: [AuthGuard] },
  { path: 'plano-editar/:id', component: PlanoEditarComponent, canActivate: [AuthGuard] },
  { path: 'classe-listar', component: ClasseListarComponent, canActivate: [AuthGuard] },
  { path: 'classe-editar', component: ClasseEditarComponent, canActivate: [AuthGuard] },
  { path: 'classe-editar/:id', component: ClasseEditarComponent, canActivate: [AuthGuard] },
];

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: null
};

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [PlanoListarComponent, PlanoEditarComponent, ClasseListarComponent, ClasseEditarComponent]
})
export class TabelaApoioModule { }
