import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AssociacaoPendenteService } from 'src/app/core/services/associacao-pendente.service';
import { NotifierService } from 'angular-notifier';
import { Associado } from 'src/app/shared/models/associado';
import { Router } from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { AssociadoPendencia } from 'src/app/shared/models/associado-pendencia';

@Component({
  selector: 'app-associacao-pendente',
  templateUrl: './associacao-pendente.component.html',
  styleUrls: ['./associacao-pendente.component.css']
})
export class AssociacaoPendenteComponent implements OnInit {

  @ViewChild('justificativaForm') justificativaForm;

  form: FormGroup;

  associados: Associado;
  breadCrumb;
  submitted: boolean;
  codigo_associado: any;
  carregando: boolean;
  contagem: any;
  total: any;
  percentual: any;
  carregando_paginacao: boolean;
  configuracao: any;
  aprovacao_pendente: Associado;
  codigo_associado_sincronia: any;
  associado_sincronia: any;
  associado_sincronia_dados: any;
  registro_rejeitado: Associado;

  constructor(private appComponent: AppComponent,
              private associacaoPendenteService: AssociacaoPendenteService,
              private router: Router,
              private notifierService: NotifierService) {

  }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Associados", caminho: "/associacao-pendente"}
    ];
    this.appComponent.setTitulo('Registros Aguardando Aprovação');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.configuracao = JSON.parse(localStorage.getItem('configuracao'));

    this.form = new FormGroup({
      justificativa_rejeicao: new FormControl('', [Validators.required]),
    });

    this.listar();
    this.listarRejeitado();
  }

  definirCodigo(codigo_associado_sincronia) {
    this.codigo_associado_sincronia = codigo_associado_sincronia;
  }

  listar(contagem = null): void {
    this.associados = null;
    this.carregando_paginacao = true;

    // associados com aprovacao pendentes
    this.associacaoPendenteService.list(contagem, false).subscribe((data: Associado) => 
    { 
      this.aprovacao_pendente = data;
      console.log(this.aprovacao_pendente);
    }, error => console.log(error));

  }

  listarRejeitado(contagem = null): void {
    this.associados = null;
    this.carregando_paginacao = true;

    // associados com aprovacao pendentes
    this.associacaoPendenteService.list(contagem, true).subscribe((data: Associado) => 
    { 
      this.registro_rejeitado = data;
      console.log(this.registro_rejeitado);
    }, error => console.log(error));

  }

  visualizar(codigo_associado_sincronia) {
    // associados com aprovacao pendentes
    this.associacaoPendenteService.find(codigo_associado_sincronia).subscribe((data: Associado) => {
      this.associado_sincronia = data;
      console.log(this.associado_sincronia);
    }, (error) => {
      console.log(error);
    });
  }

  aprovar(codigo_associado_sincronia):void {
    this.carregando = true;
    let associado = {'aprovado': 'S'};
    this.associacaoPendenteService.save(associado, codigo_associado_sincronia).subscribe((data) => {
      this.carregando = false;
      this.router.navigate(['dados-administrativos/' + data.codigo_pessoa]);
      this.notifierService.notify( 'success', 'Cadastro APROVADO com sucesso.' );
    }, (error) => {
      this.carregando = false;
      this.notifierService.notify( 'error', 'Não foi possível APROVAR o cadastro, ocorreu um erro. ' + error );
    });
  }

  apagar(codigo_associado_sincronia):void {
    this.carregando = true;
    let associado = this.form.value as Associado;
    this.associacaoPendenteService.delete(associado, codigo_associado_sincronia).subscribe((data) => {
      this.carregando = false;
      this.form.patchValue({justificativa_rejeicao: null});
      this.listar();
      this.listarRejeitado();
      this.notifierService.notify( 'success', 'Registro DELETADO com sucesso.' );
    }, (error) => {
      this.carregando = false;
      this.notifierService.notify( 'error', 'Não foi possível DELETAR o registro, ocorreu um erro. ' + error );
    });
  }

}
