<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
    <div class="panel-heading">
        <div class="">
            <button routerLink="/classe-editar" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Nova Classe</button>
        </div>
    </div>
    <div class="panel-body">

        <div class="tab-container">
            <ul class="nav nav-tabs">
                <li class="active"><a href="#aberta" data-toggle="tab">Vigentes</a></li>
                <li><a href="#encerrada" data-toggle="tab">Encerradas</a></li>
            </ul>
            <div class="tab-content">
                <div id="aberta" class="tab-pane active cont">
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="classes && classes.length > 0">
                                <tr class="odd gradeX" *ngFor="let dado of classes">
                                    <td class="col-md-10">{{dado.nome}}</td>
                                    <td class="text-center" style="font-size: 22px;">
                                        <a href="classe-editar/{{dado.codigo_classe}}" (click)="definirCodigo(dado.codigo_classe)"><span class="mdi mdi-edit"></span></a>
                                        &nbsp;
                                        &nbsp;
                                        <a data-toggle="modal" data-target="#md-footer-success" (click)="definirCodigo(dado.codigo_classe)"><span class="mdi mdi-delete"></span></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!classes">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="classes && classes.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="7">
                                        Não existem classes vigentes.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="encerrada" class="tab-pane cont">
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                  <th>Nome</th>
                                  <th>Desativado em</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="classes_inativos && classes_inativos.length > 0">
                              <tr class="odd gradeX" *ngFor="let dado of classes_inativos">
                                <td class="col-md-8">{{dado.nome}}</td>
                                <td class="col-md-2">{{dado.deleted_at | date: 'dd/MM/yyyy HH:mm'}}</td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="!classes_inativos">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="classes_inativos && classes_inativos.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="7">
                                        Não existem classes desativadas.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

    </div>
  </div>
</div>

<!--Modal Footer-->
<div id="md-footer-success" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja DESATIVAR esta classe?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagar(codigo_classe)">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<app-footer></app-footer>