<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
    <div class="panel-heading">
        <div class="">
            <button routerLink="/plano-editar" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Novo Plano</button>
        </div>
    </div>
    <div class="panel-body">

        <div class="tab-container">
            <ul class="nav nav-tabs">
                <li class="active"><a href="#aberta" data-toggle="tab">Vigentes</a></li>
                <li><a href="#encerrada" data-toggle="tab">Encerrados</a></li>
            </ul>
            <div class="tab-content">
                <div id="aberta" class="tab-pane active cont">
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nº</th>
                                    <th>Classe</th>
                                    <th>Descrição</th>
                                    <th>Valor</th>
                                    <th>Padrão</th>
                                    <th>Criado em</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="planos && planos.length > 0">
                                <tr class="odd gradeX" *ngFor="let dado of planos">
                                    <td class="col-md-1">{{dado.codigo_plano}}</td>
                                    <td class="col-md-2"><span *ngIf="dado.codigo_classe">{{dado.classe.nome}}</span><span *ngIf="!dado.codigo_classe"> - </span></td>
                                    <td class="col-md-4">{{dado.descricao}}</td>
                                    <td class="col-md-1">{{dado.valor}}</td>
                                    <td class="col-md-1"><span *ngIf="dado.padrao">Sim</span><span *ngIf="!dado.padrao">Não</span></td>
                                    <td class="col-md-2">{{dado.created_at | date: 'dd/MM/yyyy HH:mm'}}</td>
                                    <td class="text-center" style="font-size: 22px;">
                                        <a href="plano-editar/{{dado.codigo_plano}}" (click)="definirCodigo(dado.codigo_plano)"><span class="mdi mdi-edit"></span></a>
                                        &nbsp;
                                        &nbsp;
                                        <a data-toggle="modal" data-target="#md-footer-success" *ngIf="!dado.padrao" (click)="definirCodigo(dado.codigo_plano)"><span class="mdi mdi-delete"></span></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!planos">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="planos && planos.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="7">
                                        Não existem planos vigentes.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="encerrada" class="tab-pane cont">
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                  <th>Nº</th>
                                  <th>Classe</th>
                                  <th>Valor</th>
                                  <th>Desativado em</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="planos_inativos && planos_inativos.length > 0">
                              <tr class="odd gradeX" *ngFor="let dado of planos_inativos">
                                <td class="col-md-1">{{dado.codigo_plano}}</td>
                                <td class="col-md-4"><span *ngIf="dado.codigo_classe">{{dado.classe.nome}}</span><span *ngIf="!dado.codigo_classe"> - </span></td>
                                <td class="col-md-2">{{dado.valor}}</td>
                                <td class="col-md-2">{{dado.deleted_at | date: 'dd/MM/yyyy HH:mm'}}</td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="!planos_inativos">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="planos_inativos && planos_inativos.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="7">
                                        Não existem planos encerrados.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

    </div>
  </div>
</div>

<!--Modal Footer-->
<div id="md-footer-success" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja DESATIVAR este plano?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagar(codigo_plano)">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<app-footer></app-footer>