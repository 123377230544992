import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { NotifierService } from 'angular-notifier';
import { Classe } from 'src/app/shared/models/classe';
import { ClasseService } from 'src/app/core/services/classe.service';

@Component({
  selector: 'app-classe-listar',
  templateUrl: './classe-listar.component.html',
  styleUrls: ['./classe-listar.component.css']
})
export class ClasseListarComponent implements OnInit {

  classes: Classe;
  codigo_classe;
  breadCrumb;
  classes_inativos: Classe;
  carregando;

  constructor(private appComponent: AppComponent,
              private classeService: ClasseService,
              private notifierService: NotifierService) { }

  ngOnInit() {
    this.breadCrumb = [
      {nome: 'Configuração', caminho: 'configuracao'}, {nome: 'classes', caminho: '/classe-listar'}
    ];
    this.appComponent.setTitulo('Classes Cadastradas');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.listar();

  }

  definirCodigo(codigo_classe) {
    this.codigo_classe = codigo_classe;
  }

  listar(): void {
    // classes ativos
    this.classeService.list(false).subscribe((data: Classe) => {
      this.classes = data;
    }, error => console.log(error));

    // demandas inativas
    this.classeService.list(true).subscribe((data_inativo: Classe) => {
      this.classes_inativos = data_inativo;
    }, error => console.log(error));
  }

  apagar(codigo_classe):void {
    this.carregando = true;
    this.classeService.delete(codigo_classe).subscribe((data) => {
      this.listar();
      this.carregando = false;
      this.notifierService.notify( 'success', 'Registro DELETADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', error );
      this.carregando = false;
    });
  }

}
