import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { NotifierService } from 'angular-notifier';
import { Plano } from 'src/app/shared/models/plano';
import { PlanoService } from 'src/app/core/services/plano.service';

@Component({
  selector: 'app-plano-listar',
  templateUrl: './plano-listar.component.html',
  styleUrls: ['./plano-listar.component.css']
})
export class PlanoListarComponent implements OnInit {

  planos: Plano;
  codigo_plano;
  breadCrumb;
  planos_inativos: Plano;
  carregando;

  constructor(private appComponent: AppComponent,
              private planoService: PlanoService,
              private notifierService: NotifierService) { }

  ngOnInit() {
    this.breadCrumb = [
      {nome: 'Configuração', caminho: 'configuracao'}, {nome: 'Planos', caminho: '/plano-listar'}
    ];
    this.appComponent.setTitulo('Planos Cadastrados');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.listar();

  }

  definirCodigo(codigo_plano) {
    this.codigo_plano = codigo_plano;
  }

  listar(): void {
    // planos ativos
    this.planoService.list(false).subscribe((data: Plano) => {
      this.planos = data;
    }, error => console.log(error));

    // demandas inativas
    this.planoService.list(true).subscribe((data_inativo: Plano) => {
      this.planos_inativos = data_inativo;
    }, error => console.log(error));
  }

  apagar(codigo_plano):void {
    this.carregando = true;
    this.planoService.delete(codigo_plano).subscribe((data) => {
      this.listar();
      this.carregando = false;
      this.notifierService.notify( 'success', 'Registro DELETADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', error );
      this.carregando = false;
    });
  }

}
