import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssociacaoPendenteService {

  constructor(private http: HttpClient) {}

  private _Url = environment.UrlPrincipal + '/api/associadosincronia';associadosincroniadeletar
  private _UrlDelete = environment.UrlPrincipal + '/api/associadosincroniadeletar';

  list(contagem = 10, rejeitado = false) {
      return this.http.get(this._Url + '?rejeitado=' + rejeitado + '&contagem='+contagem)
                      .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this._Url + "/" + id)
                    .catch(this.handleError);
  }

  save(dados, codigo): Observable<any>{
    let http;
    if(codigo || dados.codigo){
        http = this.http.put(this._Url + "/" + codigo, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(dados, codigo) {
    let http;
    http = this.http.put(this._UrlDelete + "/" + codigo, dados);
    http.map((res) => {
      return res;
    }).catch(this.handleError);
    return http;
  }

  private handleError(error: Response) {
    return error.json();
  }

}
