import { ModalidadeJuridico } from './../../../shared/models/modalidade-juridico';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidation } from 'src/app/shared/validation/form-validation';

import { AssociadoService } from 'src/app/core/services/associado.service';
import { Associado } from 'src/app/shared/models/associado';
import { AssociadoBeneficioService } from 'src/app/core/services/associado-beneficio.service';
import { AssociadoBeneficio } from 'src/app/shared/models/associado-beneficio';
import { BeneficioTipoService } from 'src/app/core/services/beneficio-tipo.service';
import { BeneficioTipo } from 'src/app/shared/models/beneficio-tipo';
import { UfService } from 'src/app/core/services/uf.service';
import { Uf } from 'src/app/shared/models/uf';
import { Pais } from 'src/app/shared/models/pais';
import { PaisService } from 'src/app/core/services/pais.service';
import { ModalidadeJuridicoService } from 'src/app/core/services/modalidade-juridico.service';

import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import * as moment from 'moment';
registerLocaleData(localePt)

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.css']
})
export class BeneficiosComponent implements OnInit {

  @ViewChild('patrocinioForm') formValues;
  @ViewChild('planoSaudeForm') formPlanoSaudeValues;
  @ViewChild('seguroVidaForm') formSeguroVidaValues;
  @ViewChild('auxilioJuridicoIndividualForm') formAuxilioJuridicoIndividualValues;
  @ViewChild('auxilioJuridicoColetivoForm') formAuxilioJuridicoColetivoValues;
  @ViewChild('auxilioNatalidadeForm') formAuxilioNatalidadeValues;
  @ViewChild('auxilioFuneralForm') formAuxilioFuneralValues;
  @ViewChild('beneficioPostMortemForm') formPostMortemValues;
  @ViewChild('auxilioParticipacaoCientificaForm') formValuesParticipacaoCientifica;
  @ViewChild('CartaFiancaForm') formValuesCartaFianca;
  @ViewChild('ClubeConvenioForm') formValuesClubeConverio;

  formPatrocinio: FormGroup;
  formSeguroVida: FormGroup;
  formPlanoSaude: FormGroup;
  formAuxilioJuridicoIndividual: FormGroup;
  formAuxilioJuridicoColetivo: FormGroup;
  formAuxilioNatalidade: FormGroup;
  formAuxilioFuneral: FormGroup;
  formPostMortem: FormGroup;
  formAuxilioParticipacaoCientifica: FormGroup;
  formCartaFianca: FormGroup;
  formClubeConvenio: FormGroup;

  breadCrumb;

  associado: Associado;
  beneficios: AssociadoBeneficio;
  beneficios_inativos: AssociadoBeneficio;
  beneficiosTipos: BeneficioTipo;

  codigo_pessoa: any;
  codigo_beneficio_associado: number;
  beneficioTipo: BeneficioTipo;
  codigo_associado_beneficio: any;
  existe_associado_beneficio: number;
  submitted: boolean;
  Ufs: Uf;
  internacional: any = '';
  paises: Pais;
  modalidades: ModalidadeJuridico;
  contratacao_tipo: any;
  nome: any;
  matricula_dpf: any;
  tipo: any;
  classe_nome: any;
  codigo_beneficio: number;
  existe_associado_beneficio_inativo: any;
  associadoBeneficio: any;
  convenio_tipo: any;
  associado_apcf: any;
  associado_apcf_sindical: any;
  associado_tipo: string;
  associado_matricula_dpf: string;
  associado_nome: string;
  aviso_requerimento: any;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private associadoService: AssociadoService,
              private ufService: UfService,
              private paisService: PaisService,
              private modalidadeJuridicoService: ModalidadeJuridicoService,
              private associadoBeneficioService: AssociadoBeneficioService,
              private beneficioTipoService: BeneficioTipoService,
              private notifierService: NotifierService) {
              this.codigo_pessoa = this.routerParam.snapshot.params.id;
              this.associado_nome = localStorage.getItem('associado_nome');
              this.associado_matricula_dpf = localStorage.getItem('associado_matricula_dpf');
              this.associado_tipo = localStorage.getItem('associado_tipo');
              this.associado_apcf = localStorage.getItem('associado_apcf');
              this.associado_apcf_sindical = localStorage.getItem('associado_apcf_sindical');
  }

  ngOnInit() {

    this.breadCrumb = [
      {nome: 'Associados', caminho: 'associado-listar'}, {nome: 'Benefícios', caminho: '/beneficios/' + this.codigo_pessoa}
    ];
    this.appComponent.setTitulo('Benefícios');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.formSeguroVida = this.formBuilder.group({
      data_concessao: [null, [Validators.required, FormValidation.dataValidator]],
      valor: [null, Validators.required]
    });

    this.formPlanoSaude = this.formBuilder.group({
      plano_tipo: [null, Validators.required],
      nome_seguradora: [null, Validators.required],
      data_adesao: [null, [Validators.required, FormValidation.dataValidator]],
      data_exclusao: [null, FormValidation.dataValidator]
    });

    this.formPatrocinio = this.formBuilder.group({
      patrocinio_tipo: [null],
      data_concessao: [null, [Validators.required, FormValidation.dataValidator]],
      valor: [null, Validators.required],
      descricao: [null, Validators.required]
    });

    this.formAuxilioParticipacaoCientifica = this.formBuilder.group({
      internacional: [null],
      sigla_uf: [null],
      cidade: [null],
      evento: [null, Validators.required],
      data_evento: [null, [Validators.required, FormValidation.dataValidator]],
      data_concessao: [null, [Validators.required, FormValidation.dataValidator]],
      valor: [null, Validators.required]
    });

    this.formCartaFianca = this.formBuilder.group({
      endereco_imovel: [null],
      sigla_uf: [null],
      cidade: [null],
      data_inicio_vigencia: [null, [Validators.required, FormValidation.dataValidator]],
      data_fim_vigencia: [null, [Validators.required, FormValidation.dataValidator]],
      data_concessao: [null, [Validators.required, FormValidation.dataValidator]]
    });

    this.formAuxilioJuridicoIndividual = this.formBuilder.group({
      data_concessao: [null, [Validators.required, FormValidation.dataValidator]],
      valor: [null, Validators.required]
    });

    this.formAuxilioJuridicoColetivo = this.formBuilder.group({
      codigo_modalidade_juridico: [null, Validators.required],
      contratacao_tipo: [null, Validators.required],
      valor: [null],
      data_concessao: [null],
      nome_escritorio_advocacia: [null],
      nome_contratado: [null],
      oab_contratado: [null],
      endereco_contratado: [null],
      telefone_contratado: [null],
      email_contratado: [null],
    });

    this.formAuxilioNatalidade = this.formBuilder.group({
      data_concessao: [null, [Validators.required, FormValidation.dataValidator]],
      valor: [null, Validators.required]
    });

    this.formAuxilioFuneral = this.formBuilder.group({
      data_concessao: [null, [Validators.required, FormValidation.dataValidator]],
      valor: [null, Validators.required]
    });

    this.formPostMortem = this.formBuilder.group({
      data_obito: [null, FormValidation.dataValidator],
      data_requerimento: [null, FormValidation.dataValidator],
      valor: [null, Validators.required],
      data_concessao: [null, Validators.required],
      nome_beneficiario: [null, Validators.required],
      grau_parentesco: [null, Validators.required]
    });

    this.formClubeConvenio = this.formBuilder.group({
      convenio_tipo: [Validators.required],
      data_emissao_declaracao: [null, [Validators.required, FormValidation.dataValidator]],
      finalidade: [null, Validators.required]
    });

    this.obterUf();
    this.obterPais();
    this.listar();
    this.listarInativos();
    this.obterBeneficioTipo();
    this.obterModalidadeJuridico();

  }

  definirTipoEvento(internacional) {
    this.internacional = internacional;
  }

  definirTipoContratacao(valor) {
    this.contratacao_tipo = valor;
  }

  definirTipoConvenio(valor) {
    this.convenio_tipo = valor;
  }

  definirCodigo(codigo_associado_beneficio) {
    this.codigo_associado_beneficio = codigo_associado_beneficio;
  }

  limpar() {
    this.codigo_associado_beneficio = null;
    this.associadoBeneficio = null;
    this.formPatrocinio.reset();
    this.formPlanoSaude.reset();
    this.formSeguroVida.reset();
    this.formAuxilioParticipacaoCientifica.reset();
    this.formCartaFianca.reset();
    this.formAuxilioJuridicoIndividual.reset();
    this.formAuxilioJuridicoColetivo.reset();
    this.formAuxilioNatalidade.reset();
    this.formAuxilioFuneral.reset();
    this.formClubeConvenio.reset();
  }

  obterUf() {
    this.ufService.list()
    .subscribe((data: Uf) => this.Ufs = data,
                error => console.log(error));
  }

  obterModalidadeJuridico() {
    this.modalidadeJuridicoService.list()
    .subscribe((data: ModalidadeJuridico) => this.modalidades = data,
                error => console.log(error));
  }

  obterPais() {
    this.paisService.list()
    .subscribe((data: Pais) => this.paises = data,
                error => console.log(error));
  }

  obterBeneficioTipo() {
    this.beneficioTipoService.list().subscribe((data: BeneficioTipo) => this.beneficiosTipos = data, error => console.log(error));
  }

  listar(): void {
    // planos ativos
    this.associadoBeneficioService.list(false, this.codigo_pessoa).subscribe((data: AssociadoBeneficio) => {
      this.beneficios = data;
      console.log(data);
      
      if(data[0]) {
        this.nome = data[0].pessoa.nome;
        this.matricula_dpf = data[0].associado.matricula_dpf;
        this.tipo = data[0].associado.tipo;
        this.existe_associado_beneficio = data[0].codigo_associado_beneficio;
        this.associado_apcf = data[0].associado_apcf;
        this.associado_apcf_sindical = data[0].associado_apcf_sindical;
      }
    }, error => console.log(error));
  }

  listarInativos(): void {
    this.associadoBeneficioService.list(true, this.codigo_pessoa).subscribe((data: AssociadoBeneficio) => {
      this.beneficios_inativos = data;
      this.existe_associado_beneficio_inativo = data[0].codigo_associado_beneficio;
    }, error => console.log(error));
  }

  obter(codigo_associado_beneficio, codigo_beneficio_tipo): void {
    this.associadoBeneficio = null;
    // planos ativos
    this.associadoBeneficioService.find(codigo_associado_beneficio).subscribe((data: AssociadoBeneficio) => {
      this.associadoBeneficio = data[0];

      if(codigo_beneficio_tipo == 6) {
        this.formSeguroVida = this.formBuilder.group({
          data_concessao: data[0].data_concessao,
          valor: data[0].valor
        });
      }
  
      if(codigo_beneficio_tipo == 5) {
        this.formPlanoSaude.patchValue({
          plano_tipo: data[0].plano_tipo,
          nome_seguradora: data[0].nome_seguradora,
          data_adesao: data[0].data_adesao,
          data_exclusao: data[0].data_exclusao
        });
      }
  
      if(codigo_beneficio_tipo == 7) {
        this.formPatrocinio.patchValue({
          patrocinio_tipo: data[0].patrocinio_tipo,
          data_concessao: data[0].associadobeneficio.data_concessao,
          valor: data[0].associadobeneficio.valor,
          descricao: data[0].descricao
        });
      }
  
      if(codigo_beneficio_tipo == 4) {
        this.formAuxilioParticipacaoCientifica.patchValue({
          internacional: data[0].internacional,
          sigla_uf: data[0].sigla_uf,
          cidade: data[0].cidade,
          evento: data[0].evento,
          data_evento: data[0].data_evento,
          data_concessao: data[0].associadobeneficio.data_concessao,
          valor: data[0].associadobeneficio.valor
        });
      }
  
      if(codigo_beneficio_tipo == 2) {
        this.formCartaFianca.patchValue({
          endereco_imovel: data[0].endereco_imovel,
          sigla_uf: data[0].sigla_uf,
          cidade: data[0].cidade,
          data_inicio_vigencia: data[0].data_inicio_vigencia,
          data_fim_vigencia: data[0].data_fim_vigencia,
          data_concessao: data[0].associadobeneficio.data_concessao
        });
      }

      if(codigo_beneficio_tipo == 10) {
        this.contratacao_tipo = data[0].contratacao_tipo;
        this.formAuxilioJuridicoIndividual.patchValue({
          data_concessao: data[0].associadobeneficio.data_concessao,
          valor: data[0].associadobeneficio.valor
        });
      }
  
      if(codigo_beneficio_tipo == 1) {
        this.formAuxilioJuridicoColetivo.patchValue({
          codigo_modalidade_juridico: data[0].codigo_modalidade_juridico,
          contratacao_tipo: data[0].contratacao_tipo,
          valor: data[0].associadobeneficio.valor,
          data_concessao: data[0].associadobeneficio.data_concessao,
          nome_escritorio_advocacia: data[0].nome_escritorio_advocacia,
          nome_contratado: data[0].nome_contratado,
          oab_contratado: data[0].oab_contratado,
          endereco_contratado: data[0].endereco_contratado,
          telefone_contratado: data[0].telefone_contratado,
          email_contratado: data[0].email_contratado,
        });
      }

      if(codigo_beneficio_tipo == 8) {
        this.formAuxilioNatalidade.patchValue({
          data_concessao: data[0].data_concessao,
          valor: data[0].valor
        });
      }

      if(codigo_beneficio_tipo == 9) {
        this.formAuxilioFuneral.patchValue({
          data_concessao: data[0].data_concessao,
          valor: data[0].valor
        });
      }

      if(codigo_beneficio_tipo == 3) {
        this.formPostMortem.patchValue({
          data_obito: data[0].data_obito,
          nome_beneficiario: data[0].nome_beneficiario,
          grau_parentesco: data[0].grau_parentesco,
          data_requerimento: data[0].associadobeneficio.data_requerimento,
          data_concessao: data[0].associadobeneficio.data_concessao,
          valor: data[0].associadobeneficio.valor
        });
        this.aviso_requerimento = data[0].aviso_requerimento;
        console.log(data[0].aviso_requerimento);
        
      }

      if(codigo_beneficio_tipo == 11) {
        this.formClubeConvenio.patchValue({
          convenio_tipo: data[0].convenio_tipo,
          data_emissao_declaracao: data[0].data_emissao_declaracao,
          finalidade: data[0].finalidade
        });
      }

    }, error => console.log(error));
  }

  incluirPatrocinio(): void {
    this.submitted = true;
    const patrocinio = this.formPatrocinio.value as AssociadoBeneficio;
    patrocinio.codigo_associado_beneficio = this.codigo_associado_beneficio;
    patrocinio.codigo_associado = this.codigo_pessoa;
    patrocinio.codigo_beneficio_tipo = 7;
    if (this.formPatrocinio.valid) {
      this.associadoBeneficioService.save(patrocinio, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formPatrocinio').modal('hide');
        this.formPatrocinio.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formPatrocinio);
    }
  }

  incluirPlanoSaude(): void {
    this.submitted = true;
    const planoSaude = this.formPlanoSaude.value as AssociadoBeneficio;
    planoSaude.codigo_associado_beneficio = this.codigo_associado_beneficio;
    planoSaude.codigo_associado = this.codigo_pessoa;
    planoSaude.codigo_beneficio_tipo = 5;
    if (this.formPlanoSaude.valid) {
      this.associadoBeneficioService.save(planoSaude, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formPlanoSaude').modal('hide');
        this.formPlanoSaude.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formPlanoSaude);
    }
  }

  incluirSeguroVida(): void {
    this.submitted = true;
    const seguroVida = this.formSeguroVida.value as AssociadoBeneficio;
    seguroVida.codigo_associado_beneficio = this.codigo_associado_beneficio;
    seguroVida.codigo_associado = this.codigo_pessoa;
    seguroVida.codigo_beneficio_tipo = 6;
    if (this.formSeguroVida.valid) {
      this.associadoBeneficioService.save(seguroVida, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formSeguroVida').modal('hide');
        this.formSeguroVida.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formSeguroVida);
    }
  }

  incluirAuxilioParticipacaoCientifica(): void {
    this.submitted = true;
    const auxilioParticipacaoCientifica = this.formAuxilioParticipacaoCientifica.value as AssociadoBeneficio;
    auxilioParticipacaoCientifica.codigo_associado_beneficio = this.codigo_associado_beneficio;
    auxilioParticipacaoCientifica.codigo_associado = this.codigo_pessoa;
    auxilioParticipacaoCientifica.codigo_beneficio_tipo = 4;
    if (this.formAuxilioParticipacaoCientifica.valid) {
      this.associadoBeneficioService.save(auxilioParticipacaoCientifica, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formAuxilioParticipacaoCientifica').modal('hide');
        this.formAuxilioParticipacaoCientifica.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formAuxilioParticipacaoCientifica);
    }
  }

  incluirCartaFianca(): void {
    this.submitted = true;
    const cartaFianca = this.formCartaFianca.value as AssociadoBeneficio;
    cartaFianca.codigo_associado_beneficio = this.codigo_associado_beneficio;
    cartaFianca.codigo_associado = this.codigo_pessoa;
    cartaFianca.codigo_beneficio_tipo = 2;
    if (this.formCartaFianca.valid) {
      this.associadoBeneficioService.save(cartaFianca, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formCartaFianca').modal('hide');
        this.formCartaFianca.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formAuxilioParticipacaoCientifica);
    }
  }

  incluirAuxilioJuridicoIndividual(): void {
    this.submitted = true;
    const auxilioJuridicoIndividual = this.formAuxilioJuridicoIndividual.value as AssociadoBeneficio;
    auxilioJuridicoIndividual.codigo_associado_beneficio = this.codigo_associado_beneficio;
    auxilioJuridicoIndividual.codigo_associado = this.codigo_pessoa;
    auxilioJuridicoIndividual.codigo_beneficio_tipo = 10;
    if (this.formAuxilioJuridicoIndividual.valid) {
      this.associadoBeneficioService.save(auxilioJuridicoIndividual, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formAuxilioJuridicoIndividual').modal('hide');
        this.formAuxilioJuridicoIndividual.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formAuxilioJuridicoIndividual);
    }
  }

  incluirAuxilioJuridicoColetivo(): void {
    this.submitted = true;
    const auxilioJuridicoColetivo = this.formAuxilioJuridicoColetivo.value as AssociadoBeneficio;
    auxilioJuridicoColetivo.codigo_associado_beneficio = this.codigo_associado_beneficio;
    auxilioJuridicoColetivo.codigo_associado = this.codigo_pessoa;
    auxilioJuridicoColetivo.codigo_beneficio_tipo = 1;
    if (this.formAuxilioJuridicoColetivo.valid) {
      this.associadoBeneficioService.save(auxilioJuridicoColetivo, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formAuxilioJuridicoColetivo').modal('hide');
        this.formAuxilioJuridicoColetivo.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formAuxilioJuridicoColetivo);
    }
  }

  incluirAuxilioNatalidade(): void {
    this.submitted = true;
    const auxilioNatalidade = this.formAuxilioNatalidade.value as AssociadoBeneficio;
    auxilioNatalidade.codigo_associado_beneficio = this.codigo_associado_beneficio;
    auxilioNatalidade.codigo_associado = this.codigo_pessoa;
    auxilioNatalidade.codigo_beneficio_tipo = 8;
    if (this.formAuxilioNatalidade.valid) {
      this.associadoBeneficioService.save(auxilioNatalidade, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formAuxilioNatalidade').modal('hide');
        this.formAuxilioNatalidade.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formAuxilioNatalidade);
    }
  }

  incluirAuxilioFuneral(): void {
    this.submitted = true;
    const auxilioFuneral = this.formAuxilioFuneral.value as AssociadoBeneficio;
    auxilioFuneral.codigo_associado_beneficio = this.codigo_associado_beneficio;
    auxilioFuneral.codigo_associado = this.codigo_pessoa;
    auxilioFuneral.codigo_beneficio_tipo = 9;
    if (this.formAuxilioFuneral.valid) {
      this.associadoBeneficioService.save(auxilioFuneral, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formAuxilioFuneral').modal('hide');
        this.formAuxilioFuneral.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formAuxilioFuneral);
    }
  }

  incluirAuxilioPostMortem(): void {
    this.submitted = true;
    const postMortem = this.formPostMortem.value as AssociadoBeneficio;
    postMortem.codigo_associado_beneficio = this.codigo_associado_beneficio;
    postMortem.codigo_associado = this.codigo_pessoa;
    postMortem.codigo_beneficio_tipo = 3;
    if (this.formPostMortem.valid) {
      this.associadoBeneficioService.save(postMortem, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formPostMortem').modal('hide');
        this.formPostMortem.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formPostMortem);
    }
  }

  incluirClubeConvenio(): void {
    this.submitted = true;
    const clubeConvenio = this.formClubeConvenio.value as AssociadoBeneficio;
    clubeConvenio.codigo_associado_beneficio = this.codigo_associado_beneficio;
    clubeConvenio.codigo_associado = this.codigo_pessoa;
    clubeConvenio.codigo_beneficio_tipo = 11;
    if (this.formClubeConvenio.valid) {
      this.associadoBeneficioService.save(clubeConvenio, this.codigo_associado_beneficio).subscribe((data) => {
        this.listar();
        this.codigo_associado_beneficio = null;
        $('body').removeClass('modal-open');
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formClubeConvenio').modal('hide');
        this.formClubeConvenio.reset();
        this.notifierService.notify( 'success', 'Registro cadastrado com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      console.log(this.formClubeConvenio);
    }
  }

  restaurar(associadoBeneficioService): void {
    this.associadoBeneficioService.restore(associadoBeneficioService).subscribe((data) => {
      this.listar();
      this.listarInativos();
      this.notifierService.notify( 'success', 'Registro RESTAURADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível RESTAURAR o registro, ocorreu um erro. ' + error );
    });
  }

  apagar(codigo_associado_beneficio): void {
    this.associadoBeneficioService.delete(codigo_associado_beneficio).subscribe((data) => {
      this.listar();
      this.listarInativos();
      this.notifierService.notify( 'success', 'Registro DELETADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível DELETAR o registro, ocorreu um erro. ' + error );
    });
  }

}
