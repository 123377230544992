import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AssociadoService } from 'src/app/core/services/associado.service';
import { NotifierService } from 'angular-notifier';
import { Associado } from 'src/app/shared/models/associado';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AssociadoSituacaoTipoService } from 'src/app/core/services/associado-situacao-tipo.service';
import { AssociadoSituacaoTipo } from 'src/app/shared/models/associado-situacao-tipo';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociadoSituacaoService } from 'src/app/core/services/associado-situacao.service';
import { AssociadoSituacao } from 'src/app/shared/models/associado-situacao';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DatePipe } from '@angular/common'; //import do datePipe

@Component({
  selector: 'app-associado-situacao',
  templateUrl: './associado-situacao.component.html',
  styleUrls: ['./associado-situacao.component.css'],
  providers: [DatePipe] //DatePipe como provider
})
export class AssociadoSituacaoComponent implements OnInit {

  @ViewChild('situacaoForm') formValues;

  form: FormGroup;
  AssociadoSituacaoTipos: AssociadoSituacaoTipo;
  associadoSituacoes: AssociadoSituacao;
  codigo_associado_situacao;
  codigo_associado_situacao_tipo;
  tipo_situacao;
  codigo_pessoa;
  breadCrumb;
  carregando;
  nome;
  matricula_dpf;
  tipo;
  me;
  situacao_possivel: string;
  submitted;
  afiliacao_tipo: any;
  associadoSituacoesApcfSindical: AssociadoSituacao;
  associadoSituacoesApcf: AssociadoSituacao;
  situacao_apcf: string;
  situacao_sindical: string;
  atualiza: boolean;
  novo_cadastro: boolean;
  associado_nome: string;
  associado_matricula_dpf: string;
  associado_tipo: string;
  associado_apcf: string;
  associado_apcf_sindical: string;
  associacao_tipo: string;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private associadoService: AssociadoService,
              private associadoSituacaoService: AssociadoSituacaoService,
              private authenticationService: AuthenticationService,
              private associadoSituacaoTipoService: AssociadoSituacaoTipoService,
              private routerParam: ActivatedRoute,
              private notifierService: NotifierService) { 

              this.codigo_pessoa = this.routerParam.snapshot.params.id;
              this.associado_nome = localStorage.getItem('associado_nome');
              this.associado_matricula_dpf = localStorage.getItem('associado_matricula_dpf');
              this.associado_tipo = localStorage.getItem('associado_tipo');
              //this.associado_apcf = localStorage.getItem('associado_apcf');
              //this.associado_apcf_sindical = localStorage.getItem('associado_apcf_sindical');

  }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Associados", caminho: "/associado-listar"}
    ];
    this.appComponent.setTitulo('Cadastro de Situação (Adesão/Baixa)');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      codigo_associado_situacao_tipo: [null],
      data_ocorrencia: [null, Validators.required],
      //situacao: [null, Validators.required],
      //associado_apcf: [null, Validators.required],
      //associado_apcf_sindical: [null, Validators.required],
    });

    if(this.codigo_pessoa) {
      this.obterAssociado();
      this.obterApcf();
      this.obterApcfSindical();
    }
    this.obterAssociadoSituacaoTipo();

  }

  definirTipoSituacao(tipo_situacao) {
    this.tipo_situacao = tipo_situacao;
  }

  definirTipoAfiliacao(afiliacao_tipo) {
    this.afiliacao_tipo = afiliacao_tipo;
  }

  novoCadastro() {
    this.atualiza = false;
    this.novo_cadastro = true;    
  }

  obterAssociado(): void {
    this.carregando = true;
    this.associadoService.find(this.codigo_pessoa).subscribe((data: Associado) => {
      this.nome = data.pessoa.nome,
      this.matricula_dpf = data.matricula_dpf;
      this.tipo = data.tipo;
      this.associado_apcf = data.associado_apcf;
      this.associado_apcf_sindical = data.associado_apcf_sindical;
      this.carregando = false;
    });
  }

  obterAssociadoSituacaoTipo(): void {
    this.associadoSituacaoTipoService.list()
    .subscribe((data: AssociadoSituacaoTipo) => this.AssociadoSituacaoTipos = data,
                error => console.log(error));
  }

  obterApcf(): void {
    this.carregando = true;
    this.associadoSituacaoService.list(this.codigo_pessoa, false).subscribe((data: AssociadoSituacao) => {
      if(data.length > 0) {
        this.situacao_apcf = data[0].situacao;
        this.associadoSituacoesApcf = data;
      }
      this.carregando = false;

    });
  }

  obterApcfSindical(): void {
    this.carregando = true;
    this.associadoSituacaoService.list(this.codigo_pessoa, true).subscribe((data: AssociadoSituacao) => {
      if(data.length > 0) {
        this.situacao_sindical = data[0].situacao;
        this.associadoSituacoesApcfSindical = data;
      }
      this.carregando = false;

    });
  }

  limpar() {
    this.form.reset();
    this.codigo_associado_situacao = null;
    this.atualiza = null;
  }

  carregar(codigo_associado_situacao): void {
    this.codigo_associado_situacao = codigo_associado_situacao;
    this.atualiza = true;
    this.novo_cadastro = false;
    this.associadoSituacaoService.find(this.codigo_associado_situacao).subscribe((data: AssociadoSituacao) => {
      this.form.patchValue({
        data_ocorrencia: this.datePipe.transform(data.data_ocorrencia, 'dd/MM/yyyy'),
        situacao: data.situacao,
        codigo_associado_situacao_tipo: data.codigo_associado_situacao_tipo,
      });
      this.obterAssociadoSituacaoTipo();
    });
  }

  definirCodigo(codigo_associado_situacao) {
    this.codigo_associado_situacao = codigo_associado_situacao;
  }

  apagar(codigo_associado_situacao):void {
    this.associadoSituacaoService.delete(codigo_associado_situacao).subscribe((data) => {
      this.obterApcf();
      this.obterApcfSindical();
      this.obterAssociado();
      this.notifierService.notify( 'success', 'Registro DELETADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', error );
    });
  }

  // convenience getter for easy access to form fields
  get f() {    
    return this.form.controls;
  }

  onSubmitSindical(): void {
    this.submitted = true;
    this.carregando = true;
    let situacao = this.form.value as AssociadoSituacao;
    console.log(this.situacao_possivel);
    if (this.form.valid) {
      situacao.codigo_pessoa = this.codigo_pessoa;
      if(!this.atualiza) {
        if(this.situacao_sindical == 'A')  {
          situacao.situacao = 'B';
        }
        if(this.situacao_sindical == 'B')  {
          situacao.situacao = 'A';
        }
      }
      situacao.codigo_associado_situacao = this.codigo_associado_situacao;
      situacao.associado_apcf = null;
      if(situacao.situacao == 'B') {
        situacao.associado_apcf_sindical = '0';
      } else {
        situacao.associado_apcf_sindical = '1';
      }
      if(situacao.situacao == 'A') {
        situacao.codigo_associado_situacao_tipo = 1;
      }
      situacao.associacao_tipo = 'S';
      this.associadoSituacaoService.save(situacao, this.codigo_associado_situacao).subscribe((data) => {
        this.obterApcfSindical();
        this.obterAssociado();
        //$('body').removeClass('modal-open');
        //$('body').css('padding-right', '');
        //$(".modal-backdrop").remove();
        //$('#formSituacaoApcf').modal('hide');
        this.form.reset();
        this.submitted = false;
        this.atualiza = null;
        this.notifierService.notify( 'success', 'Situação salva com sucesso.' );
        this.carregando = false;
      }, (error) => {
        this.carregando = false;
        this.notifierService.notify( 'error', error );
      });
    } else {
      console.log(this.form);
      this.carregando = false;
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
    }
  }

  onSubmitApcf(): void {
    this.submitted = true;
    this.carregando = true;
    let situacao = this.form.value as AssociadoSituacao;
    console.log(this.situacao_possivel);
    if (this.form.valid) {
      situacao.codigo_pessoa = this.codigo_pessoa;
      if(!this.atualiza) {
        if(this.situacao_apcf == 'A')  {
          situacao.situacao = 'B';
        }
        if(this.situacao_apcf == 'B')  {
          situacao.situacao = 'A';
        }
      }
      situacao.codigo_associado_situacao = this.codigo_associado_situacao;
      if(situacao.situacao == 'B') {
        situacao.associado_apcf = '0';
      } else {
        situacao.associado_apcf = '1';
      }
      situacao.associado_apcf_sindical = null;
      if(situacao.situacao == 'A') {
        situacao.codigo_associado_situacao_tipo = 1;
      }
      situacao.associacao_tipo = 'A';
      this.associadoSituacaoService.save(situacao, this.codigo_associado_situacao).subscribe((data) => {
        this.obterApcf();
        this.obterAssociado();
        //$('body').removeClass('modal-open');
        //$('body').css('padding-right', '');
        //$(".modal-backdrop").remove();
        //$('#formSituacaoApcf').modal('hide');
        this.form.reset();
        this.submitted = false;
        this.atualiza = null;
        this.carregando = false;
        this.notifierService.notify( 'success', 'Situação salva com sucesso.' );
      }, (error) => {
        this.carregando = false;
        this.notifierService.notify( 'error', error );
      });
    } else {
      console.log(this.form);
      this.carregando = false;
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
    }
  }

}
