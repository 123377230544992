import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AssociadoService } from 'src/app/core/services/associado.service';
import { NotifierService } from 'angular-notifier';
import { Associado } from 'src/app/shared/models/associado';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PendenciaTipoService } from 'src/app/core/services/pendencia-tipo.service';
import { PendenciaTipo } from 'src/app/shared/models/pendencia-tipo';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociadoPendenciaService } from 'src/app/core/services/associado-pendencia.service';
import { AssociadoPendencia } from 'src/app/shared/models/associado-pendencia';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-pendencias',
  templateUrl: './pendencias.component.html',
  styleUrls: ['./pendencias.component.css']
})
export class PendenciasComponent implements OnInit {

  @ViewChild('pendenciaForm') formValues;

  form: FormGroup;
  pendenciasTipo: PendenciaTipo;
  associadoPendencias: AssociadoPendencia;
  codigo_associado_pendencia;
  codigo_pendencia_tipo;
  codigo_pessoa;
  codigo_pessoa_cadastro;
  breadCrumb;
  carregando;
  nome;
  matricula_dpf;
  tipo;
  me;
  submitted;
  associado_apcf: any;
  associado_apcf_sindical: any;
  associadoPendenciasExcluidas: AssociadoPendencia;
  associado_tipo: string;
  associado_matricula_dpf: string;
  associado_nome: string;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private associadoService: AssociadoService,
              private associadoPendenciaService: AssociadoPendenciaService,
              private authenticationService: AuthenticationService,
              private pendenciaTipoService: PendenciaTipoService,
              private routerParam: ActivatedRoute,
              private notifierService: NotifierService) { 

              this.codigo_pessoa = this.routerParam.snapshot.params.id;
              this.associado_nome = localStorage.getItem('associado_nome');
              this.associado_matricula_dpf = localStorage.getItem('associado_matricula_dpf');
              this.associado_tipo = localStorage.getItem('associado_tipo');
              this.associado_apcf = localStorage.getItem('associado_apcf');
              this.associado_apcf_sindical = localStorage.getItem('associado_apcf_sindical');

  }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Associados", caminho: "/associado-listar"}
    ];
    this.appComponent.setTitulo('Cadastro de Pendências');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      codigo_pendencia_tipo: [null, Validators.required],  
      descricao: [null, Validators.required],
    });

    if(this.codigo_pessoa) {
      this.obterAssociado();
      this.obter();
      this.obterInativos();
    }

    this.authenticationService.me().subscribe((data) => {
      this.codigo_pessoa_cadastro = data.codigo_pessoa;
    });

    this.obterPendenciaTipo();
  }

  obterAssociado(): void {
    this.associadoService.find(this.codigo_pessoa).subscribe((data: Associado) => {
      this.nome = data.pessoa.nome,
      this.matricula_dpf = data.matricula_dpf;
      this.tipo = data.tipo;
      this.associado_apcf = data.associado_apcf;
      this.associado_apcf_sindical = data.associado_apcf_sindical;
    });
  }

  obterPendenciaTipo(): void {
    this.pendenciaTipoService.list()
    .subscribe((data: PendenciaTipo) => this.pendenciasTipo = data,
                error => console.log(error));
  }

  obter(): void {
    this.associadoPendenciaService.list(false, this.codigo_pessoa).subscribe((data: AssociadoPendencia) => {
      this.associadoPendencias = data;
    });
  }

  obterInativos(): void {
    this.associadoPendenciaService.list(true, this.codigo_pessoa).subscribe((data: AssociadoPendencia) => {
      this.associadoPendenciasExcluidas = data;
    });
  }

  finalizar(codigo_associado_pendencia) {
    let pendencia = { situacao: 'F', codigo_associado_pendencia: codigo_associado_pendencia };
    this.associadoPendenciaService.save(pendencia, codigo_associado_pendencia).subscribe((data) => {
      this.notifierService.notify( 'success', 'Pendência finalizada com sucesso.' );
      this.obter();
      this.carregando = false;
    }, (error) => {
      this.notifierService.notify( 'error', error );
    });
  }

  reativar(codigo_associado_pendencia) {
    let pendencia = { situacao: 'A', codigo_associado_pendencia: codigo_associado_pendencia };
    this.associadoPendenciaService.save(pendencia, codigo_associado_pendencia).subscribe((data) => {
      this.notifierService.notify( 'success', 'Pendência reativada com sucesso.' );
      this.obter();
      this.obterInativos();
      this.carregando = false;
    }, (error) => {
      this.notifierService.notify( 'error', error );
    });
  }

  restaurar(codigo_associado_pendencia) {
    this.associadoPendenciaService.restore(codigo_associado_pendencia).subscribe((data) => {
      this.notifierService.notify( 'success', 'Pendência restaurada com sucesso.' );
      this.obter();
      this.obterInativos();
      this.carregando = false;
    }, (error) => {
      this.notifierService.notify( 'error', error );
    });
  }

  carregar(codigo_associado_pendencia): void {
    this.codigo_associado_pendencia = codigo_associado_pendencia;
    this.associadoPendenciaService.find(this.codigo_associado_pendencia).subscribe((data: AssociadoPendencia) => {
      this.form.patchValue({
        descricao: data.descricao,
        codigo_pendencia_tipo: data.codigo_pendencia_tipo,
      });
    });
  }

  definirCodigo(codigo_associado_pendencia) {
    this.codigo_associado_pendencia = codigo_associado_pendencia;
  }

  apagar(codigo_associado_pendencia):void {
    this.associadoPendenciaService.delete(codigo_associado_pendencia).subscribe((data) => {
      this.codigo_associado_pendencia = null;
      this.obter();
      this.obterInativos();
      this.notifierService.notify( 'success', 'Registro deletado com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', error );
    });
  }

  // convenience getter for easy access to form fields
  get f() {    
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    this.carregando = true;
    let pendencia = this.form.value as AssociadoPendencia;
    if (this.form.valid) {
      pendencia.codigo_pessoa_cadastro = this.codigo_pessoa_cadastro;
      pendencia.codigo_pessoa = this.codigo_pessoa;      
      pendencia.codigo_associado_pendencia = this.codigo_associado_pendencia;
      this.associadoPendenciaService.save(pendencia, this.codigo_associado_pendencia).subscribe((data) => {
        this.codigo_associado_pendencia = null;
        this.formValues.resetForm();
        this.submitted = false;
        this.notifierService.notify( 'success', 'Pendência cadastrada com sucesso.' );
        this.obter();
        this.obterInativos();
        this.carregando = false;
      }, (error) => {
        this.notifierService.notify( 'error', error );
      });
    } else {
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
    }
  }

}
